











































import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'uOttawa1321Prelab6Q2',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    const browserLanguage = navigator.language.split('-')[0]; // Extract the language code
    return {
      inputs: {
        studentAnswer: null,
        language: browserLanguage === 'fr' ? 'fr' : 'en', // Set the initial language based on browser preference
      },
      options: [
        {
          text: 'Bromobenzene',
          value: 'bromo',
        },
        {
          text: 'Hydrobromic acid',
          value: 'acid',
        },
        {
          text: 'Benzene',
          value: 'benzene',
        },
        {
          text: '$\\ce{H3O+}$',
          value: 'hydronium',
        },
        {
          text: '$\\ce{HMgBr}$',
          value: 'bromide',
        },
      ],
      optionsFr: [
        {
          text: 'Bromobenzène',
          value: 'bromo',
        },
        {
          text: 'Acide hydrobromique',
          value: 'acid',
        },
        {
          text: 'Benzène',
          value: 'benzene',
        },
        {
          text: '$\\ce{H3O+}$',
          value: 'hydronium',
        },
        {
          text: '$\\ce{HMgBr}$',
          value: 'bromide',
        },
      ],
    };
  },
};
